.loading-wrapper {
  padding-top: 3rem;
  width: 280px;
  margin: 0 auto;
  margin-bottom: 2rem; }
  .loading-wrapper .MuiLinearProgress-root {
    margin-bottom: 0.5rem; }

.button-logout {
  padding: 12% 0% 0% 74%;
  position: absolute;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem; }
  .button-logout .MuiButtonGroup-root {
    display: inherit; }

.button-logout .MuiButton-outlinedPrimary:hover {
  background-color: #cbd42f;
  border: none;
  color: #085e14;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 19px;
  border-radius: 5px;
  font-family: "Red Hat Display", Sans-serif; }

.button-logout .MuiButton-outlinedPrimary {
  background-color: #085e14;
  color: #fff;
  margin-left: 1%;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 19px;
  border-radius: 5px;
  font-family: "Red Hat Display", Sans-serif; }
