.deportes-main {
  color: #767676; }
  .deportes-main .field-container h3 {
    color: #085e14; }
  .deportes-main .rs-100 {
    margin-left: 1%; }
  .deportes-main .AceptarJuramento {
    display: flex;
    align-items: center; }
    .deportes-main .AceptarJuramento .label-name {
      display: none; }
  .deportes-main .subtitle {
    margin-bottom: 1rem; }
  .deportes-main .main-title {
    color: #085e14; }
  .deportes-main div.card {
    margin: 1rem;
    padding: 1rem;
    background-color: #fff; }
  .deportes-main .deportes-container {
    margin: 1rem;
    margin-top: 0; }
  .deportes-main .deportes-container h3 {
    margin-top: 0; }
  .deportes-main .btn-select {
    margin: 0 auto; }
  .deportes-main .btn-container {
    width: 100%;
    display: flex;
    justify-content: center; }
    .deportes-main .btn-container .btn-title {
      margin: 15px;
      border: 2px solid #000;
      border-right-color: #000;
      border-radius: 10px;
      background: rgba(149, 194, 29, 0.15);
      border: 4px solid #95c21d;
      border-right-color: #95c21d; }
      .deportes-main .btn-container .btn-title span {
        color: #085e14;
        font-weight: 700;
        font-size: 1rem;
        font-family: "Red Hat Display", Sans-serif; }
      .deportes-main .btn-container .btn-title img {
        max-width: 80px; }
      .deportes-main .btn-container .btn-title:hover {
        border-color: #085e14;
        background: rgba(149, 194, 29, 0.5);
        border-right-color: #085e14; }

.form-control div .MuiFormHelperText-root {
  color: red;
  margin: 0px;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Red Hat Display", sans-serif; }

.deportes-main .btn-container .btn-title:disabled {
  margin: 15px;
  border: 2px solid #000;
  border-right-color: #000;
  border-radius: 10px;
  background: #95c21d;
  border: 4px solid #95c21d;
  border-right-color: #95c21d; }

.firmaTratamiento .section-container .field-container h3 {
  font-size: medium;
  text-align: center; }

.firmaTratamiento .section-container .field-container .spacerJuramento .label-name {
  float: left;
  color: #085e14;
  font-weight: 500;
  font-size: small;
  text-align: justify;
  padding: 2%; }

.firmaTratamiento .section-container .field-container .estiloTrata div .checkHabeas .MuiFormControlLabel-root .MuiTypography-root .datosAutorizo .MuiLink-root {
  color: #085e14;
  text-decoration-line: underline; }

.container .deportes-container .card .btn-container {
  width: 57%;
  height: 22px;
  display: block;
  justify-content: center;
  transform: translate(70%, 490%); }
  .container .deportes-container .card .btn-container .card-image {
    height: 133px; }

.form-container .dateFecha .MuiFormControl-root.datepicker {
  margin-top: 0px; }

.loading-wrapper-depor {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  padding: 3rem;
  color: #fff;
  margin: 0 auto;
  margin-bottom: 2rem;
  transform: translate(0%, 10%);
  align-items: center; }
  .loading-wrapper-depor div {
    width: 40%;
    align-items: center;
    margin: auto; }

.deportes-container .card .subtitle span .llevaNegrita {
  font-weight: bold; }
